.listing-modal {
    display: -ms-grid;
    display: grid;
    height: 80vh;
    -ms-grid-columns: 2fr 1fr;
    grid-template-columns: 2fr 1fr;
    -ms-grid-rows: 3fr 1fr 1fr 3fr;
    grid-template-rows: 2fr 1fr 1fr 3fr;
    grid-template-areas:
        "title picture"
        "price picture"
        "info picture"
        "desc  picture";
}

.listing-modal-title {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: title;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    min-width: 100%;
    height: max-content;
    white-space: pre-line;
    text-overflow: ellipsis;
    overflow: hidden;
}

.title {
    font-weight: 700;
    font-size: 32px !important;
}

.picture-wrapper {
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    -ms-grid-column: 2;
    grid-area: picture;
}

.listing-modal-price {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: price;
    padding: 20px 0;
}

.listing-modal-price>p {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: price;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5% 0;
    gap: 10px;
}

.price {
    font-size: 2.2rem;
    font-weight: 700;
}

.listing-modal-price>p {
    font-size: 22px;
    font-weight: bolder;
}

.listing-modal-info {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: info;
    padding-right: 20px;
}

.listing-modal-info>.card-body>.card-text {
    font-size: 18px !important;
}

.listing-modal-desc {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: desc;
    padding-right: 20px;
}

.listing-modal-desc>p {
    padding: 5px;
    font-size: 20px;
}

.full-img {
    max-width: 55%;
    border: 1px solid black
}


.carousel-caption {
    background: rgba(0, 0, 0, 0.216);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 0;
    color: white;
}


.backdrop {
    background-color: rgba(0, 0, 0, 0.809);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    padding: 10px;
}

.imageWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-height: 100vh;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.imageWrapper>img {
    max-width: 80%;
    max-height: 90vh;
    display: block;
    -o-object-fit: fill;
    object-fit: fill;
    position: fixed;
}

.close-prewiew {
    padding: 20px;
    font-size: 34px !important;
    color: #fff !important;
    z-index: 99999;
    cursor: pointer;
    position: fixed;
    margin-right: 20px;
    top: 5;
    right: 0;
}

.prewiew-caption {
    position: fixed;
    bottom: 2;
    padding: 0 !important;
}

.prewiew-caption>h5 {
    font-size: 24px;
}

@media(max-width: 983px) {
    .listing-modal {
        -ms-grid-rows: -webkit-max-content 1fr 2fr 2fr 2fr;
        -ms-grid-rows: max-content 1fr 2fr 2fr 2fr;
        grid-template-rows: -webkit-max-content 1fr 2fr 2fr 2fr;
        grid-template-rows: max-content 1fr 2fr 2fr 2fr;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "picture"
            "price"
            "info"
            "desc";
    }

    .listing-modal-title {
        font-size: 28px !important;
        padding: 5px 0;
    }

    .listing-modal-title {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }

    .picture-wrapper {
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
    }

    .listing-modal-price {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
    }

    .listing-modal-price>p {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
    }

    .listing-modal-info {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
    }

    .listing-modal-desc {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
    }
}

@media(max-width: 530px) {

    .listing-modal-title {
        font-size: 24px !important;
        padding: 0;
    }
}