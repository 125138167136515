:root {
    --primary-color: #374785;
    --secondary-color: #f76c6c;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #fbfbff;
}

.modal-dialog {
    z-index: 99999 !important;
}

.main-container {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.form-container {
    width: 96%;
    margin: auto;
    height: 100px;
    background-color: white;
    display: block;
}

.nav-tabs {
    border: none;
    margin: 0px 0px 0px 0px;
    position: absolute;
    top: -55px;
    left: 7px;
}

#tip,
.selectize-input input {
    font-size: 17px !important;
    font-weight: 400 !important;
}

.selectize-input input {
    width: auto !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=number],
textarea,
select,
.selectize-dropdown,
.selectize-input {
    font-size: 15px;
    font-family: NunitoSans;
    padding: 9px 0px 9px 9px;
    color: black;
    border: 1px solid #eee;
    background-color: #fff;
    box-shadow: none;
    font-weight: 400;
}



a:hover {
    text-decoration: none;
}

.tabs {
    /*width: 98%;*/
    height: 40px;
    margin: auto;
    position: relative;
}

.form-group {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.searchform_nadji {
    background-color: white;
    width: 100%;
    margin: auto;
    box-shadow: 3px 1px 20px 0 rgba(0, 110, 225, 0.38);
    padding: 20px;
    padding-bottom: 8px;
}

.form-control {
    height: calc(1.5em + .75rem + 4px);
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, .25);
    border-color: #fff;
}

.form-group {
    min-height: 52px;
}

.wpresidence_button {
    font-family: NunitoSans;
    text-shadow: none;
    margin: 0;
    border-radius: 0px;
    border: 1px solid;
    line-height: 2;
    border-color: #384885;
    background: #384885;
    color: #ffffff;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    padding: 10px 35px;
    text-transform: uppercase;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%);
}

.wpresidence_button:hover {
    text-decoration: none;
    background-color: white;
    background-position: left bottom;
    color: #384885;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    cursor: pointer;
}

.tab-button {
    font-family: NunitoSans !important;
}

.tab-button:not(.active) {
    opacity: 0.7;
}

.tab-div {
    border: none !important;
    color: white;
    float: left;
    display: inline-block;
    border: 1px solid var(--secondary-color);
    border-right: 1px solid #f2f2f2;
    float: left;
    display: inline-block;
    border: 1px solid var(--secondary-color);
    border-right: 1px solid #f2f2f2;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    box-shadow: 0 10px 31px 0 rgba(7, 152, 255, 0.09);
    position: absolute;
    bottom: 0;
}

.tab-div a {
    background: var(--secondary-color);
    color: #fff;
    float: left;
    padding: 13px 23px;
    text-transform: capitalize;
    font-weight: 600;
    color: #fff;
    float: left;
    padding: 13px 23px;
    text-transform: capitalize;
    font-weight: 600;
    height: 47px;
    margin-top: 3px;
}

a.active {
    margin-top: 0px !important;
    padding-bottom: 24px !important;
}

header .user li a {
    color: #001d4b !important;
    margin-bottom: 0px;
    text-decoration: none;
}

header .user li a:hover {
    color: rgb(4, 4, 39);
}

header .user li .invisible1 {
    height: 25px;
    width: 100%;
    position: absolute;
    top: 15px;
    left: 0;
    display: none;
}

header .user li .user-menu {
    box-shadow: 0 0 1px 1px rgb(110, 115, 118);
    border-radius: 1px;
    position: absolute;
    right: -50px;
    width: 180px;
    padding: 10px;
    background: #fff;
    z-index: 9999;
    display: none;
}

header .user li .user-menu ul li {
    display: block;
    border: 0;
    float: none;
    padding-bottom: 5px;
}

header .user li .user-menu ul li a {
    display: block;
}

header .user li .user-menu ul li a:hover {
    color: #323232 !important;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline
}

#loggedIn:hover .user-menu {
    display: block;
}

#loggedIn:hover .invisible1 {
    display: block;
}

.bar1,
.bar2,
.bar3 {
    width: 25px;
    height: 2px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
}

.menu {
    display: none;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px rgb(71, 159, 199);
    background-color: rgb(138, 203, 233);
    opacity: 0.8;
}

li.active,
.tab-button.active {
    background-color: var(--primary-color) !important;
    color: white !important;
}

@media screen and (min-width: 1500px) {
    .favorite:hover {
        fill: rgb(214, 81, 81);
    }
    
    .favorite-active:hover {
        fill: rgb(214, 81, 81);
    }
    
    .edit:hover {
        fill: rgb(219, 221, 73);
    }
    
    .undesirable:hover {
        fill: rgb(123, 208, 208);
    }

    .undesirable-active:hover {
        fill: rgb(123, 208, 208);
    }
}

.favorite-active {
    fill: red;
}

.undesirable-active {
    fill: aqua;
}

.input-file {
    visibility: hidden;
    display: inline;
    width: 5px;
}

.input-file-color {
    background-color: #c3c7ce80;
}

.choose-img {

    border-radius: 5px;
    padding: 5px 20px 5px 20px;
}

.choose-img:hover {
    background-color: #c3c7ce;
}

@font-face {
    font-family: custom;
    src: url(../fonts/custom.otf);
}


@font-face {
    font-family: pirulen;
    src: url(../fonts/pirulen.ttf);
}

@font-face {
    font-family: Simplifica;
    src: url(../fonts/Simplifica.ttf);
}


@font-face {
    font-family: NunitoSans;
    src: url(../fonts/NunitoSans-ExtraBold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: NunitoSans;
    src: url(../fonts/NunitoSans-LightItalic.ttf);
    font-weight: 300;
}

@font-face {
    font-family: NunitoSans;
    src: url(../fonts/NunitoSans-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: NunitoSans;
    src: url(../fonts/NunitoSans-Regular.ttf);
    font-weight: 400;
}


* {
    font-family: NunitoSans;
    font-weight: 400;
}

#data-container {
    margin-top: 20px;
}

.title-link {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}

h1 {
    font-family: NunitoSans;
    font-size: 20px;
    text-align: left;
    margin: auto;
    margin-top: 10px;
    max-height: 50px;
    overflow: hidden;
}

h5 {
    font-family: NunitoSans;
    font-size: 17px;
    color: var(--primary-color);
    margin-top: 15px;
    margin-bottom: 15px;
}

.apartment-column {
    height: 625px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
}

@media screen and (max-width: 350px) {
    .apartment-column {
        height: 650px;
    }
}

.apartment-container {
    background-color: white;
    height: 100%;
    width: 100%;
    box-shadow: 0 10px 20px 0 rgba(2, 17, 74, 0.1);
    transition-duration: 1s;
}

.apartment-container:hover {
    box-shadow: 0 3px 23px 9px rgba(7, 152, 255, 0.15) !important;
    transform: translate(0%, -10px);
    transition-duration: 1s;
}

.carousel {
    width: 100%;
}

.span-col {
    padding: 0px;
}

.info-row {
    margin: 5px;
}

/* p {
    overflow: auto;
    font-size: 15px;
    margin-bottom: 5px;
} */

p {
    overflow: auto;
    font-size: 15;
    margin-bottom: 5px;
    word-wrap: break-word;
    white-space: pre-wrap;
}

@media (max-width: 1200px) {
    .row .info-row .price-span{
        font-size: 12px;
    }
}

@media (max-width: 412px) {
    h1 {
        font-size: 18px;
    }

    h5 {
        font-size: 15px;
    }

    p {
        font-size: 12px;
    }

    .area-span{
        font-size: 14px;
    }

    .row .info-row .room-span{
        font-size: 13px;
    }

    .row .info-row .price-span{
        font-size: 11px;
    }
}

@media (max-width: 360px ) {
    h1 {
        font-size: 16px;
    }

    h5 {
        font-size: 13px;
    }

    p {
        font-size: 11px;
    }

    .area-span{
        font-size: 13px;
    }

    .row .info-row .room-span{
        font-size: 12px;
    }

    .row .info-row .price-span{
        font-size: 10px;
    }
}

.property_media {
    background-color: rgba(0, 0, 0, 0.35);
    color: #fff;
    position: absolute;
    bottom: 8px;
    right: 20px;
    z-index: 9;
    opacity: 0.9;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    padding-right: 5px;
    vertical-align: baseline;
    display: block;
    border-radius: 30px;
}

.property_media_up {
    background-color: rgba(0, 0, 0, 0.35);
    color: #fff;
    position: absolute;
    top: 8px;
    right: 20px;
    z-index: 9;
    opacity: 0.9;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    padding-right: 5px;
    padding-left: 5px;
    vertical-align: baseline;
    display: block;
    border-radius: 30px;
}

.property_location_image {
    background-color: rgba(0, 0, 0, 0.35);
    padding-left: 5px;
    padding-right: 5px;
    color: #fff;
    position: absolute;
    bottom: 8px;
    left: 20px;
    z-index: 9;
    opacity: 0.9;
    pointer-events: auto;
    border-radius: 30px;
    max-width: 70%;
}

.property_marker {
    background-image: url(../img/unit.png);
    background-repeat: no-repeat;
    background-position: 0px -19px;
    margin-right: 0px;
    padding-left: 15px;
}

.fa {
    margin-left: 8px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-camera:before {
    content: "\f030";
}

.room-span {
    background-image: url(../img/roomplan1.png);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: 0px 3px;
    width: auto;
    float: left;
    padding-left: 20px;
    font-size: 15px;
}

.area-span:before {
    content: '\e819';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 6px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    font-size: 13px;
}

.bath-span:before {
    content: '\e816';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 3px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
}

.price-span {
    font-size: 13px;
    color: black;
}

#pagination-container {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

#pagination-container .paginationjs .paginationjs-pages ul li {
    border: none !important;
    display: inline-block;
}

#pagination-container .paginationjs .paginationjs-pages ul li a {
    background: transparent;
    font-size: 20px;
    font-weight: 600;
    color: #f76c6c;
    height: 36px;
    line-height: 37px;
    width: auto !important;
    padding: 0 6px;
}

#pagination-container .paginationjs .paginationjs-pages ul li.active a {
    color: #fff;
}

#pagination-container .paginationjs .paginationjs-pages ul li.paginationjs-next.J-paginationjs-next a,
#pagination-container .paginationjs .paginationjs-pages ul li.paginationjs-prev.J-paginationjs-previous a {
    color: #001d4b;
    border-radius: 0;
}

#pagination-container .paginationjs .paginationjs-pages ul li.paginationjs-prev.disabled a {
    color: #8d99ae;
}

#pagination-container .paginationjs .paginationjs-pages ul li.paginationjs-next.disabled a {
    color: #8d99ae;
}

#pagination-container .paginationjs .paginationjs-pages ul li.paginationjs-prev.disabled:hover {
    background-color: #374785;
    opacity: 0.5;
    border-radius: 5px 0px 0px 5px;
}

#pagination-container .paginationjs .paginationjs-pages ul li.paginationjs-next.disabled:hover {
    background-color: #374785;
    opacity: 0.5;
    border-radius: 0px 5px 5px 0px;
}

#pagination-container .paginationjs .paginationjs-pages ul li.paginationjs-ellipsis a {
    line-height: 20px;
    font-weight: 700;
    font-size: 30px;
    opacity: 1;
    font-family: auto;
}


#pagination-container .paginationjs .paginationjs-pages ul li:hover a {
    color: #fff !important;
}

#pagination-container .paginationjs .paginationjs-pages ul li:hover {
    background: #374785;
}

#logo {
    margin: auto;
    max-width: 100%;
}

.logo-row {
    margin: 60px 0px;
}

@media screen and (max-width: 500px) {
    .logo-row {
        margin: 30px 0px;
    }
}

.results {
    margin: 10px;
    margin-left: 20px;
    color: blue;
}

#currentPage {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-color);
    padding: 0;
    margin: 0px;
    z-index: -30;
    position: inherit;
}

.dollar {
    display: inline-block;
    position: relative;
}

.symbol input {
    padding-left: 15px;
}

.symbol:before {
    position: absolute;
    right: 18px;
    top: 0;
    color: grey;
}

.euro:before {
    margin-top: 16px;
    content: "\20AC";
}

.meters:before {
    margin-top: 12px;
    content: "m\00B2";
}

#currentPage {
    padding: 15px;
}

#currentPage {
    sortBy2: 15px;
}

.euros:before {
    margin-top: 12px;
    content: "\20AC";
}

.sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.sk-cube2 {
    background-color: var(--secondary-color);
}

.sk-cube4 {
    background-color: var(--secondary-color);
}

.sk-cube1 {
    background-color: var(--primary-color);
}

.sk-cube3 {
    background-color: var(--primary-color);
}

.sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {

    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes sk-foldCubeAngle {

    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: rgba(0, 0, 0, 0.35);
    padding: 15px !important;
    border-radius: 20px;
}

#prodaja,
#izdavanje {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.show-when-loaded {
    opacity: 0;
    animation: fadeIn 3s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
}

.search-row.show-when-loaded {
    animation-delay: 0s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.selectize-dropdown {
    height: auto !important;
}

#sortBy2 {
    z-index: 100;
    position: inherit;
}




i {
    /*border: solid black;
    border-width: 0 3px 3px 0;*/
    display: inline-block;
    padding: 3px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}


.fa-angle-double-down {
    border: 0px;
    margin-left: 0px;
}

#detailedSearch,
#basicSearch {
    /*background-color: #f2fbff;*/
    padding: 0px 5px 0px 5px;
    /*border: 1px solid black;*/
}

.roomsLabel {
    display: block;
    text-align: center;
    margin-top: 5px;
}

/*-------------------------- new style ------------------------------ */
#sortByLabel {
    background-color: #f2fbff;
    border: none;
    font-weight: 700;
    color: var(--primary-color);
}

#sortiranje {
    font-size: 15px;
    font-weight: 700;
    color: var(--primary-color);
}

.logo {
    width: 180px;
}

.navbar_nadji {
    padding: 1rem 0;
}

.bg_img {
    background: url(../img/bg-top.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 72px);
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.search_nadji .tabs .tab-div {
    width: 50%;
}

.search_nadji .tabs .tab-div a {
    width: 100%;
    font-size: 35px;
    height: auto !important;
    margin-top: 0 !important;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    padding: 18px 0;
    line-height: normal;
    font-weight: 700;
}

.search_nadji .tabs .tab-div a.active {
    color: #fff;
    opacity: 1.0;
}

.searchform_nadji {
    padding: 35px 25px 70px 25px;
}

.selectize-control.multi .selectize-input>div {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.searchform_nadji .form-group select,
.searchform_nadji .form-group .selectize-input,
.form-group .form-control {
    font-size: 18px !important;
    padding: 15px 12px !important;
    line-height: normal;
    width: 100% !important;
    height: auto;
    border: 1px solid #dbdbdb !important;
    border-radius: 0;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1) !important;
    margin: 0 !important;
    color: #001d4b !important;
    font-weight: 700 !important;
}

.searchform_nadji .selectize-input {
    padding: 0 !important;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1) !important;
    border: none !important;
    border-radius: 0 !important;
}

.searchform_nadji .form-group select {
    height: calc(58px - 2px);
}

.title_page {
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    margin-bottom: 50px;
    font-weight: 700;
}

.searchform_nadji .red_design input,
.searchform_nadji .red_design select {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 3px solid #f76c6c !important;
    box-shadow: none !important;
    font-weight: 400 !important;
    padding: 15px 12px 15px 0 !important;
}

.hide_locations .displayBlock {
    display: none;
}

@media(max-width: 1080px) {
    .red_design {
        font-size: 11px !important;
    }

    .searchform_nadji .form-group .form-control {
        font-size: 13px !important;
    }

    .multiselect-container .search-wrapper .searchBox {
        font-size: 13px !important;
    }

    .multiselect-container .displayBlock .optionContainer {
        font-size: 13px !important;
    }
}

@media(max-width: 760px) {
    .red_design {
        font-size: 8px !important;
    }

    .searchform_nadji .form-group .form-control {
        font-size: 11px !important;
    }

    .multiselect-container .search-wrapper .searchBox {
        font-size: 11px !important;
    }

    .multiselect-container .displayBlock .optionContainer {
        font-size: 11px !important;
    }
}

.red_design {
    padding: 0 15px !important;
}

.menu_nadji .nav-item.active {
    background-color: transparent !important;
}

.menu_nadji .nav-item.active a {
    font-weight: 700;
}

.menu_nadji .nav-item a {
    color: #001d4b !important;
    padding: .5rem 1.5rem !important;
    font-weight: 600;
}

.footer_logo {
    width: 200px;
    margin-bottom: 20px;
}

.footer_main {
    color: aliceblue;
    background: #001d4b;
    padding: 60px 0;
}

.footer_list {
    padding: 0;
    list-style: none;
}

.footer_list li {
    margin-bottom: 10px;
}

.footer_title {
    margin-bottom: 18px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

.footer_list a {
    color: #fff;
}

.footer_social li {
    margin: 0;
    display: inline-block;
}

.footer_social li a i {
    margin: 0;
    padding: 0;
    color: #001d4b;
}

.footer_social li a {
    background: #99a4b7;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    border-radius: 80px;
    margin: 8px 8px;
}

.footer_social {
    text-align: right;
    margin-bottom: 0;
}

.br_top_1 {
    border-top: 1px solid #4d6181;
}

@media(max-width: 767px) {
    .footer_social {
        text-align: center;
        margin-top: 30px;
    }

    .copy_right {
        text-align: center;
        margin-top: 20px;
    }

    .footer_title {
        margin-top: 20px;
    }

    .title_page {
        font-size: 25px;
    }

    .search_nadji .tabs .tab-div a {
        font-size: 20px;
    }

    .title_page {
        font-size: 25px;
        margin-bottom: 40px;
    }

    .search_nadji .tabs .tab-div a {
        font-size: 20px;
    }

    .searchform_nadji .form-group select,
    .searchform_nadji .form-group input {
        font-size: 15px !important;
        padding: 12px 10px !important;
    }

    .searchform_nadji .form-group select {
        height: auto;
    }

    .wpresidence_button {
        font-size: 15px;
        padding: 10px 20px;
        bottom: -24px;
    }

}

/* AddListin page styles */

.grid {
    display: grid;
}

.max-content {
    max-width: max-content;
}

.grid-3-col {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid-4-col {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.gap-24 {
    gap: 24px;
}

.p-20 {
    padding: 20px;
}

.form-title {
    font-size: 36px;
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.grid-center {
    justify-items: center;
    align-items: center;
}

.square-card-title {
    font-size: 24px;
    font-weight: bold;
}

.square-card {
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    width: auto;
    padding: 10px;
}

.square-card:hover {
    background-color: #f8f9fa;
}

.step-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: lightgray;
    font-size: 25px;
    font-weight: bold;
    transition: 0.3s all;
}

.step-active {
    background-color: #17a2b8;
    color: #fff;
}


.steps-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 30%;
    margin-bottom: 30px;
}

.steps-container::before {
    content: "";
    position: absolute;
    height: 2px;
    background: grey;
    top: calc(35% - 1px);
    /* Aligns the line to the middle of the circle. -1px is half of the height of the line itself. */
    left: 25px;
    right: 25px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.step-name {
    font-size: 16px;
    font-weight: 600;
}



.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.add-listing-form {
    min-width: 50vw;
}

.prewiev-img {
    transition: all 0.2s ease;
    aspect-ratio: 1;
    max-width: "80px"
}

.prewiev-img:hover {
    transform: scale(2.5);
    width: auto;
}

.invalid {
    border: 2px solid #D68B8B !important;
}

.upload-pictures-x {
    background-color: rgba(0, 0, 0, 0.35);
    color: #fff;
    position: absolute;
    top: 1px;
    right: 1px;
    z-index: 9;
    opacity: 0.9;
    padding-right: 2px;
    padding-left: 2px;
    display: block;
    border-radius: 30px;
}

.upload-pictures-x {
    background-color: rgba(0, 0, 0, 0.35);
    color: #ffffff5b;
    position: absolute;
    top: 1px;
    right: 1px;
    z-index: 9;
    opacity: 0.9;
    padding-right: 2px;
    padding-left: 2px;
    display: block;
    border-radius: 30px;
}

.upload-pictures-x:hover {
    fill: white;
}

@media(max-width: 767px) {
    .form-title {
        font-size: 26px;
    }

    .step-circle {
        width: 30px;
        height: 30px;
        font-size: 18px;
    }

    .steps-container::before {
        position: absolute;
        height: 2px;
        top: calc(30% - 1px);
        /* Aligns the line to the middle of the circle. -1px is half of the height of the line itself. */
        left: 15px;
        right: 15px;
    }

    .step-name {
        font-size: 12px;
        font-weight: 600;
    }

    .steps-container {
        width: 100%;
    }

    .grid-3-col {
        grid-template-columns: 1fr;
    }

    .grid-4-col {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.searchBox{
    width: 100%;
}
